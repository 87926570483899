/* _utility.center.scss */

.u-center{
	margin-right: auto;
	margin-left: auto;
}

.u-middle {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
