/* _component.artist-list.scss */

.c-artist-list{
    position: relative;
}

    .c-artist-list__item{
        margin-bottom: ($space * 5);
        margin-top: ($space * 5);
        text-align: center;
    }

    .c-artist-list .views-field-field-artist-thumbnail img{
        width: 100%;
    }

    .c-artist-list .views-field-created{
        @include rem(font-size, 16);
        background: palette('white');
        color: palette('bg');
        font-family: $base-font-family;
        font-weight: 600;
        letter-spacing: .5px;
        margin-bottom: ($space * 2);
        margin-top: -5px;
        padding-bottom: 4px;
        padding-top: 3px;
        text-transform: uppercase;
        width: 100%;
    }

    .c-artist-list .views-field-field-track-1-title,
    .c-artist-list .views-field-field-track-2-title,
    .c-artist-list .views-field-field-track-3-title,
    .c-artist-list .views-field-field-track-4-title,
    .c-artist-list .views-field-field-track-5-title
    {
        display: none !important;
    }

    .c-artist-list .views-field-field-track-1-title,
    .c-artist-list .views-field-field-track-2-title,
    .c-artist-list .views-field-field-track-3-title,
    .c-artist-list .views-field-field-track-4-title,
    .c-artist-list .views-field-field-track-5-title
    {
        @include rem(font-size, 16);
        background: palette('black');
        border-radius: 100px;
        border: 1px solid palette('white');
        color: palette('bg');
        font-family: $heading-font-family;
        font-weight: 700;
        margin-bottom: ($space * 2);
        margin-top: ($space * 2);
        padding: 6px ($space * 3) 4px;
        position: relative;
        text-align: left;

        //&:before{
        //    position: absolute;
        //    content: url(../images/ui/icon-play.svg);
        //    top: 4px;
        //    left: $space;
        //}
    }

    .c-artist-list .views-field-title{
        @include rem(font-size, 28);
        font-family: $heading-font-family;
        font-weight: 600;
        letter-spacing: .5px;
        margin-bottom: ($space * 2);
        text-transform: uppercase;
        a {
            color: palette('bg') !important;

            &:hover{
                text-decoration: none;
            }
        }
    }

    .c-artist-list .views-field-view-node a{
    	background: palette('black');
    	border-color: palette('white');
    	border-radius: 100px;
    	border-style: solid;
    	border-width: 1px;
        color: palette('bg');
        /* hide this
        display: inline-block;
        */
        display: none;
    	padding: 10px 15px 9px;
    	transition: background $transition linear;
        @include rem(font-size, 16);
        color: palette('white');
        font-family: $base-font-family;
        font-weight: 600;
        text-transform: uppercase;
    }
