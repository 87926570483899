//========================================
//=            Media/Breakpoint          =
//========================================
@mixin media($media-query) {
    $breakpoint-found: false;

    // Loop and check our pre-defined breakpoints
    @each $breakpoint in $breakpoints {
        $name: nth($breakpoint, 1);
        $declaration: nth($breakpoint, 2);

        @if $media-query == $name and $declaration {
            $breakpoint-found: true;

            @media only screen and #{$declaration} {
                @content;
            }
        }
    }

    // If not in our pre-defined breakpoints lets output the media query
    @if not $breakpoint-found {
        @media only screen and #{$media-query} {
            @content;
        }
    }

}


//===================================
//=            Placeholder         =
//===================================
    @mixin placeholder() {
        &::-webkit-input-placeholder {
            @content;
        }

        &:-moz-placeholder {
            @content;
        }

        &::-moz-placeholder {
            @content;
        }

        &:-ms-input-placeholder {
            @content;
        }
    }


//===========================
//=            REM          =
//===========================
    @mixin rem($property, $values...) {
        $max: length($values);
        //$pxValues: '';
        $remValues: '';

        @for $i from 1 through $max {
            $value: strip-units(nth($values, $i));
            //$pxValues: #{$pxValues + $value}px;

            //@if $i < $max {
            //        $pxValues: #{$pxValues + " "};
            //}
        }

        @for $i from 1 through $max {
            $value: strip-units(nth($values, $i));
            $remValues: #{$remValues + $value / strip-units($base-font-size)}rem;

            @if $i < $max {
                    $remValues: #{$remValues + " "};
            }
        }

        //#{$property}: $pxValues;
        #{$property}: $remValues;
    }



//===================================
//=           Width Setup           =
//===================================
@mixin width-setup($namespace:"", $attribute:"", $suffix:"") {
	$prefix: '.' + $namespace + '-';

    $attribute-prefix: null;
    // Deprechiated as IE10 does not support flex-basis
    //@if $enable-flex and $attribute == 'flex' {
    //    $attribute-prefix: 0 0 ;
    //}

	/**
	 * Whole
	 */
	#{$prefix}#{full}#{$suffix} { #{$attribute}: $attribute-prefix 100%; }


	/**
	 * Halves
	 */
	#{$prefix}#{half}#{$suffix} { #{$attribute}: $attribute-prefix 50%; }


	/**
	 * Thirds
	 */
	#{$prefix+'1\\/3'+$suffix} { #{$attribute}: $attribute-prefix 33.333%; }
	#{$prefix+'2\\/3'+$suffix} { #{$attribute}: $attribute-prefix 66.666%; }


	/**
	 * Quarters
	 */
	#{$prefix+'1\\/4'+$suffix} { #{$attribute}: $attribute-prefix 25%; }
	#{$prefix+'2\\/4'+$suffix} { @extend #{$prefix}#{half}#{$suffix}; }
	#{$prefix+'3\\/4'+$suffix} { #{$attribute}: $attribute-prefix 75%; }


	/**
	 * Fifths
	 */
	#{$prefix+'1\\/5'+$suffix} { #{$attribute}: $attribute-prefix 20%; }
	#{$prefix+'2\\/5'+$suffix} { #{$attribute}: $attribute-prefix 40%; }
	#{$prefix+'3\\/5'+$suffix} { #{$attribute}: $attribute-prefix 60%; }
	#{$prefix+'4\\/5'+$suffix} { #{$attribute}: $attribute-prefix 80%; }


	/**
	 * Sixths
	 */
	#{$prefix+'1\\/6'+$suffix} { #{$attribute}: $attribute-prefix 16.666%; }
	#{$prefix+'2\\/6'+$suffix} { @extend #{$prefix+'1\\/3'+$suffix}; }
	#{$prefix+'3\\/6'+$suffix} { @extend #{$prefix}#{half}#{$suffix}; }
	#{$prefix+'4\\/6'+$suffix} { @extend #{$prefix+'2\\/3'+$suffix}; }
	#{$prefix+'5\\/6'+$suffix} { #{$attribute}: $attribute-prefix 83.333%; }


	/**
	 * Eighths
	 */
	#{$prefix+'1\\/8'+$suffix} { #{$attribute}: $attribute-prefix 12.5%; }
	#{$prefix+'2\\/8'+$suffix} { @extend #{$prefix+'1\\/4'+$suffix}; }
	#{$prefix+'3\\/8'+$suffix} { #{$attribute}: $attribute-prefix 37.5%; }
	#{$prefix+'4\\/8'+$suffix} { @extend #{$prefix}#{half}#{$suffix}; }
	#{$prefix+'5\\/8'+$suffix} { #{$attribute}: $attribute-prefix 62.5%; }
	#{$prefix+'6\\/8'+$suffix} { @extend #{$prefix+'3\\/4'+$suffix}; }
	#{$prefix+'7\\/8'+$suffix} { #{$attribute}: $attribute-prefix 87.5%; }

	/**
	 * Nineths
	 */
	#{$prefix+'1\\/9'+$suffix}{ #{$attribute}: $attribute-prefix 11.111%; }
	#{$prefix+'2\\/9'+$suffix}{ #{$attribute}: $attribute-prefix 22.222%; }
	#{$prefix+'3\\/9'+$suffix}{ @extend #{$prefix+'1\\/3'+$suffix}; }
	#{$prefix+'4\\/9'+$suffix}{ #{$attribute}: $attribute-prefix 44.444%; }
	#{$prefix+'5\\/9'+$suffix}{ #{$attribute}: $attribute-prefix 55.556%; }
	#{$prefix+'6\\/9'+$suffix}{ @extend #{$prefix+'2\\/3'+$suffix}; }
	#{$prefix+'7\\/9'+$suffix}{ #{$attribute}: $attribute-prefix 77.778%; }
	#{$prefix+'8\\/9'+$suffix}{ #{$attribute}: $attribute-prefix 88.889%; }


	/**
	 * Tenths
	 */
	#{$prefix+'1\\/10'+$suffix} { #{$attribute}: $attribute-prefix 10%; }
	#{$prefix+'2\\/10'+$suffix} { @extend #{$prefix+'1\\/5'+$suffix}; }
	#{$prefix+'3\\/10'+$suffix} { #{$attribute}: $attribute-prefix 30%; }
	#{$prefix+'4\\/10'+$suffix} { @extend #{$prefix+'2\\/5'+$suffix}; }
	#{$prefix+'5\\/10'+$suffix} { @extend #{$prefix}#{half}#{$suffix}; }
	#{$prefix+'6\\/10'+$suffix} { @extend #{$prefix+'3\\/5'+$suffix}; }
	#{$prefix+'7\\/10'+$suffix} { #{$attribute}: $attribute-prefix 70%; }
	#{$prefix+'8\\/10'+$suffix} { @extend #{$prefix+'4\\/5'+$suffix}; }
	#{$prefix+'9\\/10'+$suffix} { #{$attribute}: $attribute-prefix 90%; }


	/**
	 * Twelfths
	 */
	#{$prefix+'1\\/12'+$suffix} { #{$attribute}: $attribute-prefix 8.333%; }
	#{$prefix+'2\\/12'+$suffix} { @extend #{$prefix+'1\\/6'+$suffix}; }
	#{$prefix+'3\\/12'+$suffix} { @extend #{$prefix+'1\\/4'+$suffix}; }
	#{$prefix+'4\\/12'+$suffix} { @extend #{$prefix+'1\\/3'+$suffix}; }
	#{$prefix+'5\\/12'+$suffix} { #{$attribute}: $attribute-prefix 41.666% }
	#{$prefix+'6\\/12'+$suffix} { @extend #{$prefix}#{half}#{$suffix}; }
	#{$prefix+'7\\/12'+$suffix} { #{$attribute}: $attribute-prefix 58.333%; }
	#{$prefix+'8\\/12'+$suffix} { @extend #{$prefix+'2\\/3'+$suffix}; }
	#{$prefix+'9\\/12'+$suffix} { @extend #{$prefix+'3\\/4'+$suffix}; }
	#{$prefix+'10\\/12'+$suffix} { @extend #{$prefix+'5\\/6'+$suffix}; }
	#{$prefix+'11\\/12'+$suffix} { #{$attribute}: $attribute-prefix 91.666%; }
}
