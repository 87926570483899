/* _utility.font-size */

.u-text-meta{
	@include rem(font-size, 12px);
}

.u-text-small{
	@include rem(font-size, 12px);
}

.u-text-large{
	@include rem(font-size, 48px);
}

.u-text-huge{
	@include rem(font-size, 96px);
}

@each $heading in $headings {
    $name: nth($heading, 1);
    $font-size: nth($heading, 2);
    $line-height: nth($heading, 3);

	.u-text-#{$name}{
		@include rem(font-size, $font-size);
		line-height: ($line-height / $font-size);
	}
}
