/* _utility.display.scss */

//
// Standard Declarations
.u-table{
	display: table;
}

.u-table-cell{
	display: table-cell;
}

.u-table-row{
	display: table-row;
}

.u-invisable{
	visibility: hidden;
}

.u-visable{
	visibility: visible;
}

.u-flex{
	display: flex;
}

.u-inline{
    display: inline;
}

.u-inline-block{
    display: inline-block;
}

.u-block{
    display: block;
}

.u-hidden{
    display: none;
}

.u-print-block{
	@media print {
		display: block;
	}
}

.u-print-inline-block{
	@media print {
		display: inline-block;
	}
}

.u-print-hidden {
	@media print {
		display: none;
	}
}

//
// Responsive Declarations
@if $responsive {
	@each $name in $breakpoint-modifiers {
		@include media($name) {

			.u-table{
				&\@#{$name} {
					display: table;
				}
			}

			.u-table-cell{
				&\@#{$name} {
					display: table-cell;
				}
			}

			.u-table-row{
				&\@#{$name} {
					display: table-row;
				}
			}

			.u-invisable{
				&\@#{$name} {
					visibility: hidden;
				}
			}

			.u-visable{
				&\@#{$name} {
					visibility: visible;
				}
			}

			.u-inline{
				&\@#{$name} {
		    		display: inline;
				}
			}

			.u-inline-block{
				&\@#{$name} {
		    		display: inline-block;
				}
			}

			.u-block{
				&\@#{$name} {
		    		display: block;
				}
			}

			.u-hidden{
				&\@#{$name} {
		    		display: none;
				}
			}

			.u-print-block{
				&\@#{$name} {
					@media print {
						display: block;
					}
				}
			}

			.u-print-inline-block{
				&\@#{$name} {
					@media print {
						display: inline-block;
					}
				}
			}

			.u-print-hidden {
				&\@#{$name} {
					@media print {
						display: none;
					}
				}
			}


		}
	}
}
