/* _utility.audio.scss */

.u-audio{
    background: palette('bg');
    border-radius: 100px;
    border: 1px solid palette('white');
    height: auto;
    width: 100%;
    position: relative;
    padding-left: ($space * 4);
    padding-right: ($space * 4);

    @include media(s) {
        padding-bottom: $space;
        padding-top: $space;
    }
}

.u-audio__name{
    @include rem(font-size, 20);
    color: palette('white');
    display: inline-block;
    font-family: $heading-font-family;
    width: 49%;

    @include media(sl) {
        width: 48%;
    }

    @include media(s) {
        margin-bottom: 0;
        text-align: center;
        width: 99%;
    }
}


.u-audio__player{
    display: inline-block;
    width: 50% !important;
    height: 60px !important;

    @include media(s) {
        width: 99% !important;
    }

    // Wrapper
    .mejs-controls{
        background: transparent;
        height: 60px !important;
        position: relative;
    }

    // Play/Pause
    .mejs-controls div:first-child{
        width: 60px;
        height: 42px;
    }

    .mejs-button button {
        width: 42px;
        height: 42px;
        background: transparent url("../images/ui/audio-play.svg") no-repeat;
    }

    .mejs-pause button,
    .mejs-stop button {
        background: transparent url("../images/ui/audio-pause.svg") no-repeat;
    }


    // Time and Playback Bar
    .mejs-currenttime-container,
    .mejs-duration-container {
        padding-top: 24px !important;
    }

    .mejs-currenttime,
    .mejs-duration {
        font-size: 16px;
        font-family: 'Noto Sans', sans-serif;
    }

    .mejs-time-rail {
        padding-top: 20px !important;
    }

    .mejs-mute{
        //display: none !important;
        width: 1px !important;
        opacity: 0;
    }
}
