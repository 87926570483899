//
// Would you like the site to be Responsive?
$responsive: true !default;

//
// Would you like push and pull classes enabled?
$grid-push-classes: false !default;
$grid-pull-classes: false !default;

//
// Would you like the grid to be built using Flexbox?
$enable-flex: false !default;

//
// Gutter widths
$grid__gutter: 32px !default;

//
// Max wrapper width
$grid__wrapper: 1280px !default;

//
// Define your breakpoints. The first value is the prefix that shall be used for
// your classes (e.g. `.palm--one-half`), the second value is the media query
// that the breakpoint fires at.
$breakpoints: (
    'xs' '(max-width: 320px)',
    's' '(max-width: 480px)',
    'sl' '(max-width: 550px)',
    'm' '(max-width: 800px)',
    'l' '(max-width: 1024px)'
) !default;


//
// Define which namespaced breakpoints you would like to generate for each of
// widths, push and pull. This is handy if you only need pull on, say, desk, or
// you only need a new width breakpoint at mobile sizes. It allows you to only
// compile as much CSS as you need. All are turned on by default, but you can
// add and remove breakpoints at will.
$breakpoint-modifiers: ('l', 'm', 'sl', 's', 'xs') !default;
