/* _utility.backgrounds.scss */

.u-background-{
	@each $palette, $color in $palettes {
		&#{$palette}{
			background-color: palette($palette);

			@if(palette($palette, 2)){
				&2{
					background-color: palette($palette, 2);
				}
			}

			@if(palette($palette, 3)){
				&3{
					background-color: palette($palette, 3);
				}
			}
		}
	}
}

.u-background-transparent{
	background-color: transparent;
}
