/* _component.table.scss */
.c-table-responsive{
    display: block;
    width: 100%;
    min-height: 0.1%;
    overflow-x: auto;

    table{
        white-space: nowrap;
    }
}

.c-table{

    th,
    td{
        padding: $space 0;
    }

    tr:last-child{
        th,
        td{
            border-bottom: none;
        }
    }

}

.c-table--lined{
    th,
    td{
        border-bottom: 1px solid palette('divider');
    }
}

.c-table--break{
    word-break: break-word;
}

.c-table--rwd{
    @include media(s) {
        table, tbody, th, td, tr {
    		display: block;
    	}

        thead{
            display: none;
        }

        tbody {
            tr { border-bottom: 1px solid palette('divider'); }

            td {
                /* Behave like a row */
        		position: relative;
                border: none;
                padding-bottom: $space;
                padding-top: $space;
                text-align: left;
                width: 100%;
            }

        	td[data-th] {
        		padding-left: 30%;

                &:before {
            		/* Create table header */
            		left: 6px;
            		padding-right: 10px;
            		position: absolute;
            		top: 6px;
            		white-space: nowrap;
            		width: 30%;
                    content: attr(data-th);
                    font-weight: bold;
            	}
            }
        }
    }
}
