/* _component.services.scss */

.c-services {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    @include media(l) {
        flex-wrap: wrap;
        margin-top: 30px;
    }

    h2 {
        color: #1E002D;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: .5px;
        margin: 0;
        padding-right: 12px;
        text-transform: uppercase;
    }

    p {
        padding-top: 16px;
    }

    a {
        text-align: center;
    }
}

    .c-services--inner {
        justify-content: space-between;
        margin-bottom: 80px;

        @include media(l) {
            flex-wrap: wrap;
        }
    }

    .c-service {
        width: 25%;

        @include media(m) {
            margin-bottom: 24px;
            width: 50%;
        }

        @include media(xs) {
            width: 100%;
        }
    }

    .c-service--inner {
        background-color: palette("white");
        border: 2px solid #D9D9D9;
        padding: 30px 26px 40px;
        width: 23%;

        @include media(l) {
            margin-bottom: 24px;
            width: 48%;
        }

        @include media(s) {
            padding: 24px 26px 16px;
            width: 100%;
        }
    }

    .c-services__link {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 16px;
    }

    .c-services__heading {
        margin-top: 30px;
        
        p {
            font-size: 18px;
            margin: 0;
        }

        a {
            border-bottom: 2px solid #1E002D;
        }
    }

    .c-services__head {
        margin-top: 30px;

        h2 {
            color: #1E002D;
            font-size: 26px;
            font-weight: bold;
            margin: 0;
            padding-right: 12px;
        }
    }

    .c-services__content {
        border-bottom: 1px solid #D8D8D8;
        margin-bottom: 36px;
        max-width: 75%;

        @include media(m) {
            max-width: 100%;
        }

        &:last-child {
            border-bottom: none;

            img {
                margin-right: 30px;
            }
        }

        p {
            margin-top: 24px;
        }
    }

    .c-services__flex {
        align-items: center;
        display: flex;

        @include media(m) {
            align-items: flex-start;
            flex-direction: column;
        }

        img {
            margin-right: 80px;

            @include media(m) {
                margin-bottom: 16px;
                margin-right: 0;
            }
        }

        p {
            margin: 0;
        }
    }

    .c-services__flex-accessibility {
        align-items: flex-start;
        display: flex;

        @include media(m) {
            flex-direction: column;
        }

        img {
            margin-left: 80px;

            @include media(m) {
                margin-bottom: 24px;
                margin-left: 0;
            }
        }
    }

    .c-service__list {
        columns: 2;
        margin-bottom: 1.5em;
        padding: 0 0 0 20px;
    }

    .c-services__gift {
        max-width: 75%;
        padding-top: 24px;

        @include media(m) {
            max-width: 100%;
        }
    }

    /* Sidebar */
    .inner-page-nav ul li {
        max-width: 220px;
        padding: 8px 16px;

        &.active {
            background-color: #EEE0E8;

            a {
                font-weight: 600;
            }
        }
    }