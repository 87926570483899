/* _theme.artist.scss */

.t-artist:not(.page-node-edit){
    background-color: #F2F0F0 !important;

    .columns{
        background-color: palette('white') !important;
        margin-left: 0!important;
        margin-right: 0!important;
        padding-left: 20px;
        padding-right: 20px;
    }

    .content-inner{
        margin-right: 0 !important;
    }

    .sidebar.region{
        display: none;
    }

    #footer {
        margin: auto;
        max-width: 1280px;
    }

}
