/* _utility.padding.scss */

//
// Standard Declarations

// All
.u-p-a{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			padding-top: ($space * $i);
			padding-bottom: ($space * $i);
			padding-left: ($space * $i);
			padding-right: ($space * $i);
		}
	}
}

// Top
.u-p-t{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			padding-top: ($space * $i);
		}
	}
}

// Right
.u-p-r{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			padding-right: ($space * $i);
		}
	}
}

// Bottom
.u-p-b{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			padding-bottom: ($space * $i);
		}
	}
}

// Left
.u-p-l{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			padding-left: ($space * $i);
		}
	}
}

// X Axis
.u-p-x{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			padding-left: ($space * $i);
			padding-right: ($space * $i);
		}
	}
}

// Y Axis
.u-p-y{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			padding-bottom: ($space * $i);
			padding-top: ($space * $i);
		}
	}
}


//
// Responsive Declarations
@if $responsive {
	@each $name in $breakpoint-modifiers {
		@include media($name) {

			// All
			.u-p-a{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						padding-top: ($space * $i);
						padding-bottom: ($space * $i);
						padding-left: ($space * $i);
						padding-right: ($space * $i);
					}
				}
			}

			// Top
			.u-p-t{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						padding-top: ($space * $i);
					}
				}
			}

			// Right
			.u-p-r{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						padding-right: ($space * $i);
					}
				}
			}

			// Bottom
			.u-p-b{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						padding-bottom: ($space * $i);
					}
				}
			}

			// Left
			.u-p-l{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						padding-left: ($space * $i);
					}
				}
			}

			// X Axis
			.u-p-x{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						padding-left: ($space * $i);
						padding-right: ($space * $i);
					}
				}
			}

			// Y Axis
			.u-p-y{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						padding-bottom: ($space * $i);
						padding-top: ($space * $i);
					}
				}
			}

		}
	}
}
