// Colour Palette
$palettes: (
	'bg': (
		1: #1E002D,
		2: #360150
	),

	'red': (
		1: #C00
	),

	'green': (
		1: #3AB13D,
		2: #AED800,
		3: #C3DF8B
	),

	'pink': (
		1: #F13C8A
	),

	'teal': (
		1: #00B298
	),

	'blue': (
		1: #755D81
	),

	'grey': (
		1: #898989,
		2: #444,
		3: #F2F0F0
	),

	'black': (
		1: #000,
		2: #202020
	),

	'white': (
		1: #FFF
	),

	'text':(
		1: #676767,
	),

	'divider':(
		1: #000
	)
);
