/* _component.artist.scss */

.c-artist{
    position: relative;
}

    .c-artist__title{
        color: palette('bg');
        font-family: $heading-font-family;
        text-align: center;
        font-weight: 600;
    }

    .c-artist__content {
        @include rem(font-size, 16);
        color: palette('bg');
        font-family: $base-font-family;
        line-height: (22 / 16);
        margin-left: auto;
        margin-right: auto;
    }

    .c-artist__media {

    }

    .c-artist__video {
    	position: relative;
    	padding-bottom: 56.25%; /* 16:9 */
    	padding-top: 25px;
    	height: 0;
        
        iframe {
        	position: absolute;
        	top: 0;
        	left: 0;
        	width: 100%;
        	height: 100%;
        }
    }