// Spacing Unit
$space: 8px;
$spacing-max-multiplier: 16;

// Base Font
$base-font-size: 16px !default;
$base-font-family: 'brandon-grotesque', sans-serif;
$base-line-height: 1.5;

// Headings
$heading-font-family: 'brandon-grotesque', sans-serif;
$headings: (
    'h1'	52px	64px,
    'h2'	42px	52px,
    'h3'	36px	46px,
    'h4'	24px	32px,
    'h5'	18px	24px,
) !default;

$transition: 200ms;
