/* _generic.box-sizing.scss */

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}
