/* _utility.align.scss */

//
// Standard Declarations
.u-align-left{
	text-align: left;
}

.u-align-right{
	text-align: right;
}

.u-align-center{
	text-align: center;
}

.u-align-middle{
	vertical-align: middle;
}

.u-align-top{
	vertical-align: top;
}

.u-align-bottom{
	vertical-align: bottom;
}

.u-align-texttop{
	vertical-align: text-top;
}

.u-align-initial{
	vertical-align: initial;
}

//
// Responsive Declarations
@if $responsive {
	@each $name in $breakpoint-modifiers {
		@include media($name) {

			.u-align-left{
				&\@#{$name} {
					text-align: left;
				}
			}

			.u-align-right{
				&\@#{$name} {
					text-align: right;
				}
			}

			.u-align-center{
				&\@#{$name} {
					text-align: center;
				}
			}

			.u-align-middle{
				&\@#{$name} {
					vertical-align: middle;
				}
			}

			.u-align-top{
				&\@#{$name} {
					vertical-align: top;
				}
			}

			.u-align-bottom{
				&\@#{$name} {
					vertical-align: bottom;
				}
			}

			.u-align-texttop{
				&\@#{$name} {
					vertical-align: text-top;
				}
			}

			.u-align-initial{
				&\@#{$name} {
					vertical-align: initial;
				}
			}

		}
	}
}
