/* _utility.font-weight.scss */

.u-light{
	font-weight: 300;
}

.u-normal{
	font-weight: 400;
}

.u-medium{
	font-weight: 500;
}

.u-semibold{
	font-weight: 600;
}

.u-bold{
	font-weight: 700;
}

.u-extrabold{
	font-weight: 900;
}
