/* _utility.text-columns.scss */

.u-text-columns{
	-webkit-column-gap: ($space * 3);
	-moz-column-gap: ($space * 3);
	column-gap: ($space * 3);
}

    .u-text-columns--2{
    	-webkit-columns: 2;
    	-moz-columns: 2;
    	columns: 2;
    }

    .u-text-columns--3{
    	-webkit-columns: 3;
    	-moz-columns: 3;
    	columns: 3;
    }

    .u-text-columns--4{
    	-webkit-columns: 4;
    	-moz-columns: 4;
    	columns: 4;
    }
