/* _component.checkbox.scss */

.c-checkbox{
	display: inline-block;
	vertical-align: middle;
}

	.c-checkbox input[type="checkbox"]{
		display: none;

		&:checked + .c-checkbox__trigger{
			border-color: palette('grey', 3);
			background-image: url('../img/ui/checkbox.svg');
		}
	}

	.c-checkbox__trigger{
		border-color: #D1D1D1;
		border-style: solid;
		border-width: 1px;
		display: block;
		height: ($space * 3);
		position: relative;
		width: ($space * 3);
		background-position: center;
		background-repeat: no-repeat;
	}


.amshopby-attr-selected{
	.c-checkbox__trigger{
		border-color: palette('grey', 3);
		background-image: url('../img/ui/checkbox.svg');
	}
}
