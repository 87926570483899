/* _utility.border.scss */

$border: 1px solid palette('divider');

.u-border-a{
    border: $border;
}

.u-border-b{
    border-bottom: $border;
}

.u-border-l{
    border-left: $border;
}

.u-border-r{
    border-right: $border;
}

.u-border-t{
    border-top: $border;
}

.u-border-x{
    border-left: $border; border-right: $border;
}

.u-border-y{
    border-bottom: $border; border-top: $border;
}
