@if $grid-pull-classes {
/* _utility.pull.scss */

	[class*="u-pull-"] { position:relative; }

	@if $responsive {
		@include width-setup('u-pull','right');

		@each $name in $breakpoints {
			@include media($name) {
				@include width-setup('u-pull','right','\\@'+$name);
			}
		}

		.u-pull-0 {
			right: 0;
		}
		@each $name in $breakpoint-modifiers {
			@include media($name) {
				.u-pull-0\@#{$name} {
					right: 0;
				}
			}
		}

	} @else {
		@include width-setup('u-pull','right');
	}

}
