/* _utility.flex.scss */

@if $responsive {

    // Custom Flex Order
    .u-flex-order-{
        @for $i from 1 through 12 {
            &#{$i} {
                order: $i;
            }
        }
    }

}
