/* _utility.widths.scss */

// Deprechiated as IE10 does not support flex-basis
// Width attribute
$width-attribute: 'width';
//@if $enable-flex {
//	$width-attribute: 'flex';
//}

@include width-setup('u-width', $width-attribute);

@if $responsive {

	@each $name in $breakpoint-modifiers {
		@include media($name) {
		   @include width-setup("u-width", $width-attribute, '\\@'+$name);
		}
	}

}
