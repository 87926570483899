/* _component.radio.scss */

.c-radio{
	display: inline-block;
	vertical-align: middle;
}

	.c-radio input[type="radio"]{
		display: none;

		&:checked + .c-radio__trigger{
			border-color: palette('grey', 3);
		}

		&:checked + .c-radio__trigger:before{
			display: block;
		}
	}

	.c-radio__trigger{
		border-color: #D1D1D1;
		border-radius: 50%;
		border-style: solid;
		border-width: 1px;
		display: block;
		height: ($space * 3);
		position: relative;
		width: ($space * 3);
	}

	.c-radio__trigger:before{
		background-color: palette('grey', 3);
		border-radius: 50%;
		content: '';
		display: none;
		height: ($space * 1.5);
		left: 50%;
		margin-left: -(($space * 1.5) / 2);
		margin-top: -(($space * 1.5) / 2);
		position: absolute;
		top: 50%;
		width: ($space * 1.5);
	}
