/* _component.buttons.scss */

.c-btn {
	background: palette('grey');
	border-color: transparent;
	border-style: solid;
	border-width: 1px;
	color: palette('white');
	display: inline-block;
	font-weight: 800;
	padding: 10px 15px 9px;
	transition: background $transition linear;

	&:hover{
		background: darken(palette('grey'), 10%);
	}

	&:active{
		//background: darken(palette('grey'), 15%);
	}
}

.c-btn__green {
	background-color: palette('pink');
	color: white !important;
	font-weight: 800;
	
	&:hover {
		background: darken(palette('pink'), 10%);
	}
}

.c-btn__pink {
	background-color: palette('pink');

	&:hover, &:active {
		background: darken(palette('pink'), 10%);
	}
}

.c-btn:disabled{
	border-color: transparent;
	cursor: not-allowed;
	opacity: 0.8;
	pointer-events: none;
}

.c-btn--pill{
	@include rem(font-size, 16);
	background: palette('bg');
	border-color: palette('white');
	border-radius: 100px;
	color: palette('white') !important;
	font-family: $base-font-family;
	font-weight: 600;
	min-width: 140px;
	padding-bottom: 5px;
	padding-top: 5px;
	text-align: center;
	text-transform: uppercase;

	&:hover{
		background: lighten(palette('bg'), 10%);
		text-decoration: none;
	}

	&:active{
		//background: darken(palette('white'), 15%);
	}
}