/* _component.form.scss */

.c-form{
    width: 100%;
}

.c-form__item{
    position: relative;
}

.c-form__label{
    display: block;
    margin-bottom: ($space * 0.5);
}

.validation-advice{

}
