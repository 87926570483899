/* _component.textbox.scss */

.c-textbox{
	padding: ($space * 1.5) ($space * 2);
	border: 1px solid #d1d1d1;

	@include placeholder(){
		color: palette('text');
	}
}


.c-textbox[disabled]{
	background-color: rgb(235, 235, 235);
}
