/* _theme.music.scss */

.t-music{

    .columns{
        background-color: palette('white') !important;
        margin-left: 0!important;
        margin-right: 0!important;
        padding-left: 20px;
        padding-right: 20px;
    }

    .content-inner{
        margin-right: 0 !important;
    }

    .sidebar.region{
        display: none;
    }

}
