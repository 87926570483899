/* _object.wrappers.scss */

.o-wrapper {
	padding-left: $grid__gutter;
    margin-left:  auto;
    margin-right: auto;
    padding-right: $grid__gutter;

	@if $responsive {
		max-width: $grid__wrapper;
	} @else {
		width: $grid__wrapper;
	}

    @include media(l) {
        padding-right: 20px;
    	padding-left: 20px;
    }
}
