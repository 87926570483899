/* _utility.floats.scss */

//
// Standard Declarations
.u-float-left{
	float: left;
}

.u-float-right{
	float: right;
}

.u-float-none{
	float: none;
}

//
// Responsive Declarations
@if $responsive {
	@each $name in $breakpoint-modifiers {
		@include media($name) {

			.u-float-left{
				&\@#{$name} {
					float: left;
				}
			}

			.u-float-right{
				&\@#{$name} {
					float: right;
				}
			}

			.u-float-none{
				&\@#{$name} {
					float: none;
				}
			}

		}
	}
}
