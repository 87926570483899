/* _object.grid.scss */

/**
 * Grid wrapper setup, supports inline-block & flex modes
 * change in _config.grid.scss
 */
.o-grid {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: -($grid__gutter / 2);
    margin-left: -($grid__gutter / 2);
    @if $enable-flex {
        display: flex;
        flex-wrap: wrap;
    }

    @include media(m){
        margin-right: -6px;
        margin-left: -6px;
    }
}


    .o-grid__item {
        padding-left: $grid__gutter / 2;
        padding-right: $grid__gutter / 2;
        position: relative;
        vertical-align: top;
        @if not $enable-flex {
            display: inline-block;
        }

        @include media(m){
            padding-right: 6px;
            padding-left: 6px;
        }
    }

    /**
     * Align singular grid cells vertically (`.grid__item--middle` or `.grid__item--bottom`). Extends
     * `.grid__item`.
     */
    @if $enable-flex {

        .o-grid__item--middle{
            align-self: center;
        }

        .o-grid__item--bottom{
            align-self: flex-end;
        }

    }


/**
 * Reversed grids allow you to structure your source in the opposite order to
 * how your rendered layout will appear. Extends `.grid`.
 */
.o-grid--rev {
    direction:rtl;
    text-align:left;

    > .o-grid__item {
        direction:ltr;
        text-align:left;
    }
}

/**
 * Align grid as a vertical column. Extends `.grid`.
 */
@if $enable-flex {
    .o-grid--column{
        flex-direction: column;
    }
}


/**
 * Gutterless grids have all the properties of regular grids, minus any spacing.
 * Extends `.grid`.
 */
.o-grid--full {
    margin-right: 0;
    margin-left: 0;

    > .o-grid__item {
        padding: 0;
    }
}


/**
 * Align the entire grid to the right. Extends `.grid`.
 */
.o-grid--right {
    @if $enable-flex {
        justify-content: flex-end;
    } @else {

        text-align: right;
        > .o-grid__item {
            text-align: left;
        }
    }
}


/**
 * Centered grids align grid items centrally without needing to use push or pull
 * classes. Extends `.grid`.
 */
.o-grid--center {
    @if $enable-flex {
        justify-content: center;
    } @else {

        text-align: center;
        > .o-grid__item {
            text-align: left;
        }
    }
}


/**
 * Align grid cells vertically (`.grid--middle` or `.grid--bottom`). Extends
 * `.grid`.
 */
.o-grid--middle {
    @if $enable-flex {
        align-items: center;
    } @else {

        > .o-grid__item {
            vertical-align: middle;
        }
    }
}

.o-grid--bottom {
    @if $enable-flex {
        align-items: flex-end;
    } @else {

        > .o-grid__item {
            vertical-align: bottom;
        }
    }
}


/**
 * Create grids with narrower gutters. Extends `.grid`.
 */
.o-grid--narrow {
    margin-right: -($grid__gutter / 4);
    margin-left: -($grid__gutter / 4);

    > .o-grid__item {
        padding-right: ($grid__gutter / 4);
        padding-left: ($grid__gutter / 4);
    }
}


/**
 * Create grids with wider gutters. Extends `.grid`.
 */
.o-grid--wide {
    padding-right: $grid__gutter;
    padding-left: $grid__gutter;

    > .o-grid__item {
        padding-right: $grid__gutter;
        padding-left: $grid__gutter;
    }
}
