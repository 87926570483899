/* _utility.clearfix.scss */

.u-clearfix{
	&:before,
	&:after {
	    content: '';
	    display: table;
	}

	&:after {
	    clear: both;
	}
}
