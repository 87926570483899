@if $grid-push-classes {
/* _utility.push.scss */

	[class*="u-push-"] { position:relative; }

	@if $responsive {
		@include width-setup('u-push','left');

		@each $name in $breakpoints {
			@include media($name) {
				@include width-setup('u-push','left','\\@'+$name);
			}
		}


		.u-push-0 {
			left: 0;
		}
		@each $name in $breakpoint-modifiers {
			@include media($name) {
				.u-push-0\@#{$name} {
					left: 0;
				}
			}
		}

	} @else {
		@include width-setup('u-push','left');
	}
}
