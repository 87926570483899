/* _utility.line-height.scss */

.u-line-height-1 {
    line-height: 100%;
}

.u-line-height-120 {
    line-height: 120%;
}

.u-line-height-130 {
    line-height: 130%;
}

.u-line-height-140 {
    line-height: 140%;
}
