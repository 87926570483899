/* _utility.spacing.scss */

//
// Standard Declarations
// Top
.u-m-t{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			margin-top: ($space * $i);
		}
	}
}

// Right
.u-m-r{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			margin-right: ($space * $i);
		}
	}
}

// Bottom
.u-m-b{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			margin-bottom: ($space * $i);
		}
	}
}

// Left
.u-m-l{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			margin-left: ($space * $i);
		}
	}
}

// X Axis
.u-m-x{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			margin-left: ($space * $i);
			margin-right: ($space * $i);
		}
	}
}

// Y Axis
.u-m-y{
	@for $i from 0 through $spacing-max-multiplier{
		&#{$i} {
			margin-bottom: ($space * $i);
			margin-top: ($space * $i);
		}
	}
}

//
// Responsive Declarations
@if $responsive {
	@each $name in $breakpoint-modifiers {
		@include media($name) {

			// Top
			.u-m-t{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						margin-top: ($space * $i);
					}
				}
			}

			// Right
			.u-m-r{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						margin-right: ($space * $i);
					}
				}
			}

			// Bottom
			.u-m-b{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						margin-bottom: ($space * $i);
					}
				}
			}

			// Left
			.u-m-l{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						margin-left: ($space * $i);
					}
				}
			}

			// X Axis
			.u-m-x{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						margin-left: ($space * $i);
						margin-right: ($space * $i);
					}
				}
			}

			// Y Axis
			.u-m-y{
				@for $i from 0 through $spacing-max-multiplier{
					&#{$i}\@#{$name} {
						margin-bottom: ($space * $i);
						margin-top: ($space * $i);
					}
				}
			}

		}
	}
}
