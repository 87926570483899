/* _component.hr.scss */

.c-hr{
	background-color: palette('bg');
	border: none;
	height: 1px;
}

.c-hr--short{
	width: 100%;
	max-width: 575px;
}
