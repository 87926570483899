/* _tools.animation.scss */

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }
    5% {
        opacity: 1;
    }
    80% {
        transform: rotateX(8deg);
    }
    83% {
        transform: rotateX(6deg);
    }
    92% {
        transform: rotateX(-3deg);
    }
    100% {
        transform-origin: top center;
        transform: rotateX(0deg);
    }
}
