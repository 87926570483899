/* _component.select.scss */

.c-select{
	appearance: none;
	background-color: transparent;
	background-image: url(../img/ui/select-arrow.svg);
	background-repeat: no-repeat;
	background-position: center right $space;
	padding-top: ($space * 1.5);
	padding-right: ($space * 4);
	padding-bottom: ($space * 1.5);
	padding-left: ($space * 2);
	border: 1px solid #d1d1d1;
	cursor: pointer;
}

.c-select[disabled]{
	background-color: rgb(235, 235, 235);
}
