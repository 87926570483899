/* _component.artist-pager.scss */

.c-artist-pager{
    background: #F2F0F0;
    margin-top: -20px;
    text-align: center;
    width: 100%;

    @include media(l) {
        //margin-left: -30px;
    }
}

    .c-artist-pager__link{
        @include rem(font-size, 18);
        border-bottom-color: transparent;
        border-bottom-style: solid;
        border-bottom-width: 4px;
        color: palette('bg') !important;
        display: inline-block;
        font-family: $base-font-family;
        font-weight: 600;
        letter-spacing: 2px;
        padding-bottom: ($space * 2);
        padding-left: ($space * 3);
        padding-right: ($space * 3);
        padding-top: ($space * 2);
        text-transform: uppercase;

        &:hover{
            text-decoration: none;
        }
    }

        .c-artist-pager__link.is-active{
            border-bottom-color: palette('pink');
        }
